import React, { useState, useEffect } from "react";
import Slider from "common/slider";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
export default function OfferSelider(props) {
  const { offerList, setShowFilePath,setShowTitle } = props;
  // const [slectedTab, onChangeTab] = useState(0);
  // const [slectedPopupType, onChangePopupType] = useState(0);
  const [currentindex, setcurrentindex] = useState();
  const seemore = (index) => {
    console.log("seemore -> index", index);
    //setAbouthiden(status);
    setcurrentindex(index);
  };

  const [url, setUrl] = useState("images/images/logo.png");
  const showOffers = (url) => {
    setUrl(url);
  };

  return (
    <>
      <div className="card-body">
        <div className="row align-items-center business-externo">
          <div className="col-md-12 bus-detail-cont">
            <div className="srvc--containt card-body p-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="main--srvc-slider offer-sliderr">
                    {offerList?.length == 0 ? (
                      <div className="text-center" style={{"backgroundColor" :"white","height":"400px"}}>
                      <img src="images/nodatafound.png" />
                       <span className="not-found-content">Ooops, no offers found</span>
                       </div>
                    ) : (
                      <Slider
                        arrows='true'
                        SliderArray={offerList}
                        SliderItem={(item, i) => (
                          <div className="swiper-slide offer-swiper-slider">
                            <div className="slid--box">
                              <div className="slider--ser_img offer--slide-box" key={i}>
                                <div className="iconsize">
                                  <div className="offer--slid-box">
                                    <a
                                      data-toggle="modal"
                                      data-target="#showVideo"
                                      onClick={() =>{
                                        // setShowFilePath(props.documentUrl + item.item.filefile)
                                        setShowFilePath(item.item.aws_url)
                                        setShowTitle(item?.item?.title)
                                       }
                                      }
                                    >
                                      <img
                                        src={
                                          item.item.aws_url
                                            ? // ? props.documentUrl + item.item.filefile
                                            item.item.aws_url
                                            : "images/no_image.png"
                                        }
                                        className="slid-img"
                                      />
                                    </a>

                                    <h4 className="text--title">{item.item.title}</h4>
                                    <p className="text--date">
                                      <b>Eff. Date : </b>
                                      {moment(item.item.createddate).format("MM-DD-YYYY")}
                                    </p>
                                    <p className="text--date">
                                      <b>Exp. Date : </b>
                                      {moment(item.item.expirationdate).format(
                                        "MM-DD-YYYY"
                                      )}
                                    </p>
                                    <p className={`text--date ${currentindex === item?.index ? "" : "slid-dec--text"}`}>
                                      {item.item.description != "undefined"
                                        ? item.item.description
                                        : ""}
                                    </p>
                                    {
                                      item.item.description?.length > 100 ?
                                        currentindex === item?.index ?
                                          <div onClick={() => seemore('')} className="seemore">{"Less >>"}
                                          </div> :
                                          <div onClick={() => seemore(item?.index)} className="seemore">
                                            {'See More >>'}</div>
                                        : null
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
