import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { apiCall } from "utils/httpClient";
import apiEndPoints from "utils/apiEndPoints";
import { successToast } from "utils/httpClient";
import { errorToast } from "utils/httpClient";
import moment from "moment";
import base64 from "base-64";
import { Document, Page } from "react-pdf";
import Swal from "sweetalert2";

function DocumentList() {
  let history = useHistory();
  const [editData, setEditData] = useState({
    name: "",
    filetype: "",
    createddate: "",
    expirationdate: "",
    description: "",
    documentfile: "",
    fileid: "",
  });

  const [documentListData, setDocumentListdata] = useState([]);
  const [oldData, setOldData] = useState({});
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoader, setIsLoader] = useState(false);
  const [editDocument, setEditDocument] = useState("");
  const [base_url, setBase_url] = useState();
  const [fileSizeError, setFileSizeError] = useState("");
  const currentDate = moment();
  const oneMonth = currentDate.add(1, "months");
  const todaydate = oneMonth.format("YYYY-MM-DD");
  const oneYearAgo = currentDate.subtract(1, "years");
  const todaydateoneyear = oneYearAgo.format("YYYY-MM-DD");

  const [bucket_Img_url, setBucket_Img_url] = useState();
  const [bucket_Img_url_Modal, setBucket_Img_url_Modal] = useState();
  const [title, setTitle] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isListLoading, setIsListLoading] = useState(false);

  const currentdate = moment().format("YYYY-MM-DD");

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    getDocumentList(newOffset);
    console.log("hanlepageclick", itemOffset);
  };

  useEffect(() => {
    getDocumentList(itemOffset);
    getValidationList();
  }, []);

  const [validationData, setValidationData] = useState([]);
  const [documentNumberValidation, setDocumentNumberValidation] = useState(0);
  async function getValidationList() {
    const { data } = await apiCall("GET", apiEndPoints.GETVALIDATIONLIST);
    if (data.status == 200) {
      setValidationData(data.data);
      setDocumentNumberValidation(
        data.data.find((x) => x.lable == "adddocument")
      );
    } else {
      setValidationData([]);
    }
  }

  async function getDocumentList(offset) {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    };
    setIsListLoading(true);
    const params = {
      filetype: "document",
      limit: itemsPerPage,
      offset: offset,
    };
    const { data } = await apiCall(
      "POST",
      apiEndPoints.GETVIDEODOCUMENTDATA,
      params
    );
    if (data.status == 200) {
      setIsListLoading(false);
      setBase_url(data.base_url);
      setDocumentListdata(data.data);
      const pageCount = data.total_data / itemsPerPage;
      setPageCount(pageCount);
    } else {
      setDocumentListdata([]);
      setIsListLoading(false);
    }
  }

  const [formError, setFormError] = useState({});
  function formValidation() {
    let error = {};
    let formErrorData = false;
    var userDate = editData.createddate;
    if (!editData.name) {
      formErrorData = true;
      error["name"] = "Title is required!";
    } else if (editData.name.length < 5) {
      formErrorData = true;
      error["name"] = "Name Length more then 5!";
    }

    if (!editData.description) {
      formErrorData = true;
      error["description"] = "Description is required";
    }

  /*   if (!editData.createddate) {
      formErrorData = true;
      error["createddate"] = "Effective date is required";
    }
    if (!editData.expirationdate) {
      formErrorData = true;
      error["expirationdate"] = "Expiration date is required";
    } else if (editData.expirationdate < currentdate) {
      formErrorData = true;
      error["expirationdate"] = "Expiration date should not be before today.";
    } */
    if (!editDocument) {
      formErrorData = true;
      error["editDocument"] = "Document File is required";
    }
    setFormError(error);
    return formErrorData;
  }

  const checkcountvalidation = () => {
    if (documentListData.length < documentNumberValidation.number) {
      return true;
    } else {
      Swal.fire({
        title: "Oops...",
        text: `You have already uploaded ${documentNumberValidation.number} Documents`,
        icon: "info",
        //showCancelButton: true,
        //confirmButtonColor: "#6258D3",
        cancelButtonColor: "#000",
        cancelButtonText: "OK",
        //confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {});
    }
  };

  async function handleAddDocument() {
    if (!formValidation() && checkcountvalidation()) {
      const documentData = new FormData();
      setIsLoader(true);
      documentData.append("name", editData.name);
      documentData.append("filetype", "document");
      documentData.append("createddate", editData.createddate);
      documentData.append("expirationdate", editData.expirationdate);
      documentData.append("description", editData.description);
      documentData.append("documentfile", editDocument);
      documentData.append("islicense", editData.islicense ?? false);

      const { data } = await apiCall(
        "POST",
        apiEndPoints.ADDVIDEOFILE,
        documentData
      );
      if (data.status == 200) {
        setIsLoader(false);
        successToast(data.message);
        getDocumentList(itemOffset);
        cleanSetEditData();
        setEditDocument("");
        setBucket_Img_url("");
      } else {
        cleanSetEditData();
        errorToast(data.message);
        setIsLoader(false);
      }
    }
  }

  function EditformValidation() {
    let error = {};
    let formErrorData = false;
    var userDate = editData.createddate;
    if (!editData.name) {
      formErrorData = true;
      error["name"] = "Name is required";
    } else if (editData.name.length < 5) {
      formErrorData = true;
      error["name"] = "Name Length more then 5!";
    }
    if (!editData.description) {
      formErrorData = true;
      error["description"] = "Description is required";
    }
    /* if (!editData.expirationdate) {
      formErrorData = true;
      error["expirationdate"] = "Expiration date is required";
    } else if (
      oldData.expirationdate != editData.expirationdate &&
      editData.expirationdate < currentdate
    ) {
      formErrorData = true;
      error["expirationdate"] = "Expiration date should not be before today.";
    }
    if (!editData.createddate) {
      formErrorData = true;
      error["createddate"] = "Effective date is required";
    } */

    if (fileSizeError != "") {
      formErrorData = true;
    }

    setFormError(error);
    return formErrorData;
  }

  function handleSubmit() {
    // console.log('first',documentListData.length)
    // console.log('documentNumberValidation',documentNumberValidation)

    // if(documentListData.length < documentNumberValidation.number){
    //   alert("Please enter");
    // }

    if (editData?.fileid == "") {
      handleAddDocument();
    } else {
      updateDocument();
    }
  }

  async function updateDocument() {
    if (!EditformValidation()) {
      setIsLoader(true);
      const documentData = new FormData();
      documentData.append("name", editData.name);
      documentData.append("filetype", editData.filetype);
      documentData.append("createddate", editData.createddate);
      documentData.append("expirationdate", editData.expirationdate);
      documentData.append("description", editData.description);
      documentData.append("documentfile", editDocument);
      documentData.append("fileid", editData.fileid);
      documentData.append("islicense", editData.islicense ?? false);

      const { data } = await apiCall(
        "POST",
        apiEndPoints.UPDATEVIDEOFILE,
        documentData
      );
      if (data.status == 200) {
        //setDocumentListdata(data.data);
        getDocumentList(itemOffset);
        successToast(data.message);
        setIsLoader(false);
        cleanSetEditData();
        setEditDocument("");
        setBucket_Img_url("");
      } else {
        errorToast(data.message);
        setIsLoader(false);
      }
    }
  }

  async function deleteDocument(fileid, filetype) {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    };
    const params = {
      fileid: fileid,
      filetype: filetype,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this document ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6258D3",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data } = await apiCall(
          "POST",
          apiEndPoints.VIDEODOCUMENTDELETE,
          params
        );
        if (data.status == 200) {
          getDocumentList(itemOffset);
          successToast(data.message);
          cleanSetEditData();
          setEditDocument("");
          setBucket_Img_url("");
        } else {
          errorToast(data.message);
        }
      }
    });
  }

  function cleanSetEditData() {
    setEditData({
      name: "",
      filetype: "document",
      createddate: "",
      expirationdate: "",
      description: "",
      documentfile: "",
      fileid: "",
    });
    setBucket_Img_url("");
  }

  function handleDocumentFileSize(e) {
    console.log("first");
    const validationStatus = validationData.find(
      (x) => x.lable == "documentsize"
    );

    var _size = Math.floor(e.target.files[0].size / 1000000); //MB

    if (_size >= validationStatus.number) {
      setFileSizeError(
        `Please select Less than ${
          validationStatus.number + validationStatus.type
        } file`
      );
      setEditDocument("");
    } else {
      setEditDocument(e.target.files[0]);
      setBucket_Img_url(URL.createObjectURL(e.target.files[0]));
      setFileSizeError("");
    }
  }

  async function getImage(param) {
    setIsLoader(true);
    const params = {
      fileName: param,
    };
    const { data } = await apiCall("POST", apiEndPoints.GETIMAGE, params);
    if (data.status == 200) {
      setBucket_Img_url(data.url);
      setIsLoader(false);
    } else {
      setIsLoader(false);
    }
  }

  async function getImageModal(param, name) {
    setTitle(name);
    const params = {
      fileName: param,
    };
    const { data } = await apiCall("POST", apiEndPoints.GETIMAGE, params);
    if (data.status == 200) {
      setBucket_Img_url_Modal(data.url);
    } else {
      console.log("error");
    }
  }

  return (
    <>
      {/* ---------------------------------------- add new ------------------------------ */}
      <div
        className="modal fade addNewPhoto--modal"
        id="addNewPhoto"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="container">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add new document
                </h5>
                <button
                  id="closeModel"
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>

                <div className="progress-bar" />
              </div>
              <div className="modal-body">
                {documentListData.length <
                (documentNumberValidation &&
                  documentNumberValidation.number) ? (
                  <form>
                    <div className="form-group">
                      <label className=" mb-0">Name</label>{" "}
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder="Name"
                        maxLength={100}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            name: e.target.value,
                          })
                        }
                      />
                      <small className="text-danger">
                        {!editData.name && formError.name ? formError.name : ""}
                      </small>
                    </div>
                    <div className="form-group">
                      <label className="mb-0">Effective Date</label>{" "}
                      <input
                        type="date"
                        className="form-control form-control-md"
                        placeholder="Effective Date"
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            createddate: e.target.value,
                          })
                        }
                        // min={todaydate}
                      />
                      <small className="text-danger">
                        {/* {!editData.createddate && formError.createddate
                          ? formError.createddate
                          : ""} */}
                        {formError?.createddate ? formError.createddate : ""}
                      </small>
                    </div>
                    <div className="form-group">
                      <label className="mb-0">Expiration Date2</label>
                      <input
                        type="date"
                        className="form-control form-control-md"
                        placeholder="Expiration Date"
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            expirationdate: e.target.value,
                          })
                        }
                       // min={todaydate}
                      />
                      <small className="text-danger">
                        {!editData.expirationdate && formError.expirationdate
                          ? formError.expirationdate
                          : ""}
                      </small>
                    </div>
                    <div className="form-group">
                      <label className="mb-0">Document</label>
                      <input
                        type="file"
                        className="form-control form-control-md"
                        placeholder="File"
                        // onChange={(e) => setEditData({
                        //     ...editData,
                        //     documentfile: e.target.files[0]
                        //   })
                        //}
                        // onChange={(e) => setEditDocument(e.target.files[0])}
                        onChange={(e) => handleDocumentFileSize(e)}
                        // accept=".pdf,.doc"
                        accept="application/pdf"
                      />
                      <small className="text-danger">
                        {fileSizeError != ""
                          ? fileSizeError
                          : !editData?.editDocument &&
                            formError?.editDocument &&
                            !editDocument
                          ? formError?.editDocument
                          : ""}
                      </small>
                    </div>
                    <div className="form-group">
                      <label className="mb-0">Description</label>{" "}
                      <textarea
                        className="form-control form-control-md"
                        placeholder="Description"
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            description: e.target.value,
                          })
                        }
                      />
                      <small className="text-danger">
                        {!editData.description && formError.description
                          ? formError.description
                          : ""}
                      </small>
                    </div>
                    {/* <div className="payment-check">
                       <input
                          className="form-check-input-payment"
                          type="checkbox"
                          onClick={(e) =>
                            setEditData({
                              ...editData,
                              islicense: (editData?.islicense) ? false : true,
                            })
                          }
                          checked={
                            editData?.islicense ? true : false
                          }
                        />
                        <label
                          className="form-check-label-payment"
                          htmlFor="flexCheckDefault"
                        >
                          Is License
                        </label>
                      </div> */}
                    <div className="form-group date text-right">
                      <button
                        type="button"
                        className="main-btn rounded"
                        style={{ minWidth: 160 }}
                        onClick={() => {
                          handleAddDocument();
                        }}
                      >
                        {isLoader ? (
                          <img
                            src="images/loader_white.gif"
                            style={{ width: "30px" }}
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="text-center">
                    <h5>
                      You can upload only {documentNumberValidation?.number}{" "}
                      documents.
                    </h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -------------------------------------------------- view ------------------------------------- */}
      <div
        className="modal fade addNewPhoto--modal"
        id="viewDocument"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {title}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="progress-bar" />
            </div>
            <div className="modal-body">
              {/* <embed
                src={bucket_Img_url_Modal}
                type="application/pdf"
                frameBorder="0"
                scrolling="auto"
                height="600px"
                width="100%"
              ></embed> */}
              <iframe
                style={{ display: "block" }}
                className="embed-responsive-item"
                frameBorder="0"
                //src="https://www.orimi.com/pdf-test.pdf"
                src={bucket_Img_url_Modal}
                allowfullscreen
                height="400px"
                width="100%"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          {
            /* documentListData.length <
                (documentNumberValidation &&
                  documentNumberValidation.number) ? */
            <>
              <div className="form-group container__img">
                {isLoader ? (
                  <img
                    src="images/loader_dark.gif"
                    style={{
                      width: "30px",
                    }}
                  />
                ) : (
                  <div className="text-center">
                   {/*  <embed
                      className="img__content"
                      src={bucket_Img_url}
                      type="application/pdf"
                      frameBorder="0"
                      scrolling="auto"
                      height="400px"
                      width="100%"
                    ></embed> */}
                    <iframe
                      style={{ display: "block" }}
                      className="embed-responsive-item"
                      //frameBorder="0"
                      //src="https://www.orimi.com/pdf-test.pdf"
                      src={bucket_Img_url}
                      allowfullscreen
                      height="291px"
                      width="100%"
                    ></iframe>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="file-upload" className="custom-file-upload">
                  <i className="fa fa-cloud-upload"></i> Upload PDF
                </label>
                <input
                  id="file-upload"
                  type="file"
                  onChange={(e) => handleDocumentFileSize(e)}
                  accept="application/pdf"
                  style={{ display: "none" }}
                />
                <small className="text-danger">
                  {fileSizeError != ""
                    ? fileSizeError
                    : !editData?.editDocument &&
                      formError?.editDocument &&
                      !editDocument
                    ? formError?.editDocument
                    : ""}
                </small>
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  placeholder="Title document"
                  value={editData?.name}
                  maxLength={100}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      name: e.target.value.trimStart(),
                    })
                  }
                />
                <small className="text-danger">
                  {!editData.name || formError.name ? formError.name : ""}
                </small>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="mb-0">Effective Date</label>{" "}
                    <input
                      type="date"
                      className="form-control form-control-md"
                      placeholder="Effective Date"
                      //min={moment(new Date()).format("YYYY-MM-DD")}
                      max={moment(editData.expirationdate).format("YYYY-MM-DD")}
                      value={moment(editData.createddate)
                        .format("YYYY-MM-DD")
                        .toString()}
                      onChange={(e) =>
                        setEditData({
                          ...editData,
                          createddate: e.target.value,
                        })
                      }
                      // min={todaydateoneyear}
                    />
                    <small className="text-danger">
                      {/* {!editData.createddate && formError.createddate
                        ? formError.createddate
                        : ""} */}
                      {formError?.createddate ? formError.createddate : ""}
                    </small>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="mb-0">Expiration Date</label>
                    <input
                      type="date"
                      className="form-control form-control-md"
                      placeholder="Expiration Date"
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      //min={moment(editData.createddate).format("YYYY-MM-DD")}
                      value={moment(editData.expirationdate)
                        .format("YYYY-MM-DD")
                        .toString()}
                      onChange={(e) =>
                        setEditData({
                          ...editData,
                          expirationdate: e.target.value,
                        })
                      }
                      // min={todaydate}
                    />
                    <small className="text-danger">
                      {formError.expirationdate ? formError.expirationdate : ""}
                    </small>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between"></div>
                <textarea
                  className="form-control"
                  placeholder="Description"
                  value={editData?.description}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      description: e.target.value.trimStart(),
                    })
                  }
                ></textarea>
                <small className="text-danger">
                  {!editData.description && formError.description
                    ? formError.description
                    : ""}
                </small>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="btn main-btn w-100"
                 /*  onClick={() => {
                    handleSubmit();
                  }} */
                  onClick={ () => {
                    isLoader ? console.log('save') : handleSubmit();
                  }}
                >
                  {/* {editData?.fileid ? "Update" : "Save"} */}
                  {isLoader ? (
                    <img
                      src="images/loader_white.gif"
                      style={{ width: "30px" }}
                    />
                  ) :  editData?.fileid ? "Update Document" : "Save Document"
                 }
                </button>
              </div>
            </>
            /* :
            <div className="limit-msg">{`You have uploaded ${documentNumberValidation.number} Documents`}</div>*/
          }
        </div>
        <div className="col-md-6">
          <div className="card border-8">
            <label className="card-header border-t-8 bg--smb text-white p-1">
              My list document
              <i
                onClick={() => {
                  cleanSetEditData();
                }}
                className="fa fa-plus add-new-item"
                style={{ marginLeft: "75%" }}
              ></i>
            </label>
            <div className="card-body text-center">
              <div className="row align-items-center">
                {isListLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <img className="listloadergif" src="images/infinity.gif" />
                  </div>
                ) : documentListData && documentListData.length > 0 ? (
                  <>
                    <table className="table table-sm table-responsive">
                      <thead>
                        <tr className="tbl--row">
                          <th scope="col" className="font-size-14" width="1%">
                            #
                          </th>
                          <th scope="col" className="font-size-14" width="50%">
                            Name
                          </th>
                          <th scope="col" className="font-size-14">
                            Eff. Date
                          </th>
                          <th scope="col" className="font-size-14">
                            Exp. Date
                          </th>
                          <th scope="col" className="font-size-14">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {documentListData.map((item, i) => (
                          <tr className="tbl--row" key={i}>
                            <td className="font-size-14">{i + 1}</td>
                            <td className="font-size-14">{item.name}</td>
                            <td className="font-size-14">
                              {item.createddate != "" ? moment(item.createddate).format("MM-DD-YYYY") : "-"}
                            </td>
                            <td className="font-size-14">
                              {item.expirationdate != "" ? moment(item.expirationdate).format("MM-DD-YYYY"): "-"}
                            </td>
                            <td
                              className="font-size-14"
                              style={{ display: "contents" }}
                            >
                              <a
                                onClick={() => {
                                  getImageModal(item.filefile, item.name);
                                }}
                                title="View"
                                className="cursor-pointer color__light mr-1"
                                data-toggle="modal"
                                data-target="#viewDocument"
                              >
                                <i className="fa fa-play fa__icons"></i>
                              </a>
                              <a
                                onClick={() => {
                                  setOldData(item);
                                  setEditData(item);
                                  getImage(item.filefile);
                                }}
                                title="Edit"
                                className="color__light mr-1"
                              >
                                <i className="fa fa-edit fa__icons"></i>
                              </a>
                              <a
                                onClick={() => {
                                  deleteDocument(item.fileid, item.filetype);
                                }}
                                title="Delete"
                                className="color__light"
                              >
                                <i className="fa fa-trash fa__icons"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {documentListData.length > 10 ? (
                      <>
                        <div className="btm-pagination mt-4">
                          <div className="d-flex justify-content-end">
                            <ReactPaginate
                              // previousLabel={'previous'}
                              // nextLabel={'next'}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={4}
                              pageRangeDisplayed={2}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination pagination-style-02 justify-content-center"
                              }
                              subContainerClassName={"pages pagination"}
                              activeClassName={"current active active_pg"}
                              activeLinkClassName={"current active active_pg"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link bg-gray"}
                              previousClassName={"page-item"}
                              nextClassName={"page-item"}
                              previousLinkClassName={"page-link bg-gray"}
                              nextLinkClassName={"page-link bg-gray"}
                              previousLabel={
                                <i className="fal fa-chevron-left"></i>
                              }
                              nextLabel={
                                <i className="fal fa-chevron-right"></i>
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : (
                  <div className="text-center">
                    <img
                      className="no-foundimage"
                      src="images/nodatafound.png"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentList;
